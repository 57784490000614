import React from 'react';
//import PageBanner from '../components/Common/PageBanner';
import Lightbox from 'react-image-lightbox';
//import RenamingBoxes from './RenamingBox';
import '../stylesheets/custom.css';

//for web3


import Web3 from 'web3';
import niftyriots from '../contracts/Niftyriots.json'
import axios from 'axios';


import { ToastProvider } from 'react-toast-notifications';
import WithToast from './Common/WithToast.js'



function LoadingCollection() {

  return (
    <div className="container">
      <div className="row justify-content-center pt-5">
        <div className="col-1 justify-content-right pr-0">
            <div className="shape-img4 pl-5 ">
                <img src={require("shape/shape1.svg")} alt="image" />

            </div>
        </div>
        <div className="col-2 pl-2">
          <p style={{color:"white"}}>Loading...</p>
        </div>
      </div>
    </div>
  );
}

function LoadingCollection2(param) {
   console.log(param.account)
  return (
    <div className="container">
      <div className="row justify-content-center pt-5">
        <div className="col-4 pl-2">
          {param.account != "" ?

            <p style={{color:"white"}}>You don't own any yet.</p>
          :
            <p style={{color:"white"}}>Please connect the metamask.</p>
          }
         
        </div>
      </div>
    </div>
  );
}



class OwnCollectionMKoKeShi extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      account: '',
      contract: null,
      totalSupply: 0,
      sizeToBuy: 0,
      currentPrice: 10,
      tokenIdsOwned: [],
      tokenIdsOwnedNames: [],
      nftowner: '',
      nftbal: 0,
      date: new Date(),
      tokenURI: "",
      arrayfromapi: []

    };
    this.handlerForms = this.handlerForms.bind(this);
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };

  }


  //mounting the main Element
  async componentDidMount() {
    await this.loadWeb3(this.props.dispatch)

  }

  handlerForms(status) {
    this.props.handlerForms(status)
  }

  //network and web3 logic

  async loadWeb3(dispatch) {
    console.log("loadWeb3")

    const lst = [];
    const populateData2 = (data) => {

      /*adding this object to the state*/
      this.setState({
        arrayfromapi: [...this.state.arrayfromapi, data]
      });
      this.props.setEmpty(false);
    }



    if (window.ethereum) {

      console.log("Starting up Collectopm....")
      const web3 = new Web3(window.ethereum)    // METAMASK CONNECTION
      const netId = await web3.eth.net.getId()     // gets the value of the network
      const enabledWeb3 = await ethereum.enable();
      const accounts = await web3.eth.getAccounts()

      //load balance
      if (typeof accounts[0] !== 'undefined') {
        console.log("-----getting balance-----")
        const balance = await web3.eth.getBalance(accounts[0])
        console.log("-----balance-----")
        console.log(balance)
        console.log("balance", web3.utils.fromWei(balance, 'ether'), "ETH")

        this.setState({ account: accounts[0], balance: balance, web3: web3 })

      } else {
        //window.alert('Please login with MetaMask')
        this.props.addToast('Please connect the metamask', { appearance: 'info' });
        return
      }


      //load contracts
      try {


        const contract = new web3.eth.Contract(niftyriots.abi, niftyriots.networks[netId].address)  // comes from ABI json file

        this.setState({ contract }) /// shortcut for {contract : contract}

        const totalSupply = await contract.methods.totalSupply().call()

        this.setState({ totalSupply })

        console.log("total supply", this.props.totalSupply)

        console.log("comeon.................")


        /*
        const currentPrice = await contract.methods.getNFTPrice().call()
        this.setState({currentPrice})
        console.log("currentPrice",web3.utils.fromWei(currentPrice, 'ether'),"ETH" )*/

        /*fill up the owned items
         Check that some items have been minted already*/

        if (this.state.totalSupply > 0) {


          //getting the balance of the current owner
          const currentBalanceofNekos = await contract.methods.balanceOf(accounts[0]).call()
          console.log("currentBalanceOfNekos", currentBalanceofNekos)

          if (currentBalanceofNekos > 0) {
            for (var kk = 0; kk <= currentBalanceofNekos - 1; kk++) {


              //get them using the
              const currentTokenID = await contract.methods.tokenOfOwnerByIndex(accounts[0], kk).call()
              console.log("currentToken", kk, currentTokenID)


              /*adding this ID to the state*/
              this.setState({
                tokenIdsOwned: [...this.state.tokenIdsOwned, currentTokenID]  // adds a new elemen tto an array
              })





              console.log("----fetching from API ----")
              //const tokenURI1 = await contract.methods.tokenURI(currentTokenID).call()
              //console.log("tokenURI", tokenURI1)

              /*calling the api with AXIOS*/
              axios.get("collection/"+currentTokenID, {
                headers: {
                  'Accept': 'application/json',
                  'Access-Control-Allow-Origin': '*',
                  'Access-Control-Allow-Headers': 'Content-Type'
                }
              }).then(function (response) {

                
                populateData2(response.data);

              })
                .catch(function (error) {


                  console.log(error);
                  const dummyresponse = { "name": "niftyriots-Reloaded", "description": "niftyriots-Reloaded: Dope Art created by Artificial Intelligence", "image": "https://gateway.pinata.cloud/ipfs/QmbUn2Y8DU7UnywvKYCNeQVkWkWAv3kJET51mCST8vmDv8", "internalname": "BBKHDCQRIJKPGBSR.jpg", "externalname": "selv2_00030.jpg", "tokenidbeforereveal": 30, "tokenidafterreveal": null, "sha256": null, "version": "v2", "external_url": null };
                  populateData2(dummyresponse);

                });




            }

          }


        } /*end of MINTING amount > 0 */


        else {
          console.log("Supply is 0 for now")
        }


      } catch (e) {
        console.log('Error', e)
        window.alert('ERRORs -- Generic error in the web3')
      }

    } else {
      window.alert('Please install MetaMask')
    }
  }   // END OF LOADING block chain data



  render() {

    return (

      <OwnCollectionDetail
        S3_ADDRESS={this.props.S3_ADDRESS}
        tokensRevealed={this.props.tokensRevealed}
        arrayfromapi={this.state.arrayfromapi}
        account={this.state.account}
        contract={this.state.contract}
        arrayofrealids={this.state.tokenIdsOwned}
        isEmpty = {this.props.isEmpty}
        namingStartTimestamp={this.props.namingStartTimestamp}
        handlerForms={this.handlerForms}
        stateForms={this.props.stateForms}

      />

    );
  }
}



const OwnCollectionDetail = (props) => {
  const [photoURL, setPhotoURL] = React.useState("");
  const [isOpenImage, setIsOpenImage] = React.useState(false);

  return (
    
    
    <div className="blog-area bg-282d41 ptb-custom-100 ">
        {props.isEmpty == true ?
              <LoadingCollection2 account={props.account} />
                :
              <div/>
        }

      <div className="container">
        <div className="row justify-content-center pt-5 mb-5  pb-5">


            {props.arrayfromapi.map((token, indexo) =>
              <div className="col-lg-3 col-md-6 col-6" key={indexo}>

                {props.tokensRevealed == true ?
                  <React.Fragment>
                    <div className="single-blog-post bg-FF13A7 circle-custom-artwork">
                            <div className="post-image">
                              <a href="#popup" onClick={e => { e.preventDefault(); setIsOpenImage(true); setPhotoURL(props.S3_ADDRESS + "artworks/" + token["internalname"]); }} >
                                <img src={props.S3_ADDRESS + "artworks/" + token["internalname"]} alt="image" className=" rounded" />
                              </a>
                            </div>

                            <div className="post-content">
                                <h6>
                                        <a>Token ID: {props.arrayofrealids[indexo]}</a>
                                </h6>
                            </div>
                        </div>

                  </React.Fragment>



                  :


                  <React.Fragment>

                        <div />
                       
                  </React.Fragment>


                }

                {/* Lightbox */}
                {isOpenImage && (
                  <Lightbox
                    mainSrc={photoURL}
                    onCloseRequest={() => setIsOpenImage(false)}
                  />
                )}

              </div>

            )}
          
        </div>
      </div>
    </div>

  );

}




export default WithToast(OwnCollectionMKoKeShi);
